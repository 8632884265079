import { referenceableToRelatedFilters } from "./constants"
import ReferenceableSelectorFilter from "./ReferenceableSelectorFilter"
import SelectorFilter from "./SelectorFilter"
import DateFilter from "./DateFilter"
import StringFilter from "./StringFilter"
import FilterError from "./FilterError"
import BooleanFilter from "./BooleanFilter"
import * as Sentry from "@sentry/react"

import {
    eDatePickerType,
    tFilterKey,
    tFilterKeyToQueryParam,
    tFilterDef,
    iFilterDefParams,
    tFilterDefType,
    tErrorFilterDef,
    tStandardFilterDefCreator,
} from "./types"
import { dateFilterStateDefaultGetter, enumFilterDefaultGetter } from "./utils"
import {
    defaultFilterToString,
    dateToString,
    referenceableIdsToString,
    enumLabelToString,
    booleanToString,
} from "./filter-state-string-getters"

/**
 * The purpose of filter-def-creators is to allow user configuration of filters!
 * The params object accepted by these functions passes in the user facing params.
 * Tentatively, those are: type, resourceName, multiselect, label, field, default
 * options, exact. We probably need some sort of union type.
 *
 * More work with WA-3969!
 */

export const filterKeyToQueryParam: tFilterKeyToQueryParam = {
    costCodeFilter: "cost_code_filter",
    includeDeleted: "include_deleted",
    projectStatus: "project_status",
    employeeStatus: "status",
    employeeRole: "role",
    includeHidden: "include_hidden",
    tkEntryStatus: "statuses",
}

export const errorFilterDefCreator = (): tErrorFilterDef => ({
    key: "error",
    component: FilterError,
})

export const referenceableSelectorFilterCreator = (params: iFilterDefParams): tFilterDef | tErrorFilterDef => {
    let refToRelated = params.resourceName && referenceableToRelatedFilters.get(params.resourceName)

    if (refToRelated === undefined) {
        if (params.key && params.queryParam) {
            refToRelated = {
                defaultFilterKey: params.key,
                queryParam: params.queryParam,
                defaultLabel: params.label || params.key,
                relatedFilters: [],
            }
        } else {
            return errorFilterDefCreator()
        }
    }

    // if the selector value is nested in the form's store - add it as a secondaryKey/secondaryParameterName
    // this allows us to keep the resource name as the key
    // we need both the filterKey/queryParam so we properly prefetch items for list views
    const isStoreValue = !!params?.field
    const fieldParameterName = isStoreValue ? `filter_${params.field}` : undefined

    return {
        key: params.key || refToRelated.defaultFilterKey,
        parameterName: params.parameterName || refToRelated.queryParam,
        secondaryKey: isStoreValue ? params.key : undefined,
        secondaryParameterName: fieldParameterName,
        clearable: true,
        clearedBy: params.clearedBy,
        component: ReferenceableSelectorFilter,
        multiselect: params.multiselect,
        queryParam: params.queryParam,
        resourceName: params.resourceName,
        label: params.label,
        filterStateStringGetter: referenceableIdsToString,
        isSelectorV3: params.isSelectorV3,
        isDesignSystem: params.isDesignSystem,
        ignoreRelatedFilters: params.ignoreRelatedFilters,
        extraSearchFilters: params.extraSearchFilters,
    }
}

export const enumFilterCreator = (params: iFilterDefParams): tFilterDef | tErrorFilterDef => {
    const { key } = params
    const parameterName = key && filterKeyToQueryParam[key]
    if (!key || !parameterName) return errorFilterDefCreator()

    const { defaultGetter, label, multiselect, options, valueKey } = params
    return {
        clearable: true,
        component: SelectorFilter,
        defaultGetter: defaultGetter || enumFilterDefaultGetter,
        filterStateStringGetter: enumLabelToString,
        key,
        label,
        multiselect,
        options,
        parameterName,
        valueKey,
    }
}

/** Date Range Filter */
export const dateRangeFilterDefCreator = (params: iFilterDefParams): tFilterDef | tErrorFilterDef => {
    const { isStartDateOnly, label, parameterName, datePickerType } = params
    const config: tFilterDef = {
        component: DateFilter,
        datePickerType: datePickerType || eDatePickerType.CUSTOM,
        defaultGetter: dateFilterStateDefaultGetter,
        filterStateStringGetter: dateToString,
        key: "startDate",
        label,
        parameterName: parameterName || "shift_start_time_0",
        // TODO: update DateFilter to set value with correct format and then remove this and usage
        type: "date",
    }

    return isStartDateOnly
        ? config
        : {
              secondaryKey: "endDate",
              secondaryParameterName: "shift_start_time_1",
              ...config,
          }
}

export const stringFilterDefCreator = (params: iFilterDefParams): tFilterDef | tErrorFilterDef => {
    if (params.key === undefined) {
        return errorFilterDefCreator()
    }

    return {
        key: params.key,
        parameterName: params.partialMatch ? `filter_contains_${params.field}` : `filter_${params.field}`,
        label: params.label,
        field: params.field,
        component: StringFilter,
        required: params.required,
        filterStateStringGetter: defaultFilterToString,
    }
}

export const booleanFilterDefCreator = (params: iFilterDefParams): tFilterDef | tErrorFilterDef => {
    if (params.key === undefined) {
        return errorFilterDefCreator()
    }
    const parameterName = params.key && filterKeyToQueryParam[params.key]
    return {
        key: params.key,
        parameterName: parameterName,
        label: params.label,
        field: params.field,
        component: BooleanFilter,
        required: params.required,
        filterStateStringGetter: booleanToString,
        type: "boolean",
    }
}

export const standardFilterCreators: tStandardFilterDefCreator = {
    date: dateRangeFilterDefCreator,
    enum: enumFilterCreator,
    "referenceable-selector": referenceableSelectorFilterCreator,
    string: stringFilterDefCreator,
    boolean: booleanFilterDefCreator,
}

export const filterDefCreator = (params: iFilterDefParams): tFilterDef | tErrorFilterDef => {
    if (params.type && params.type in standardFilterCreators) {
        try {
            const type = params.type as tFilterDefType
            return standardFilterCreators[type](params)
        } catch (e: any) {
            Sentry.captureMessage(e.toString())
        }
    }

    return errorFilterDefCreator()
}

export const textFieldOptionCreator = (
    // This gets used as the label on the filter/picker component itself and doesn't have
    // any other functionality tied to it.
    label: string,

    // This should be the model name (Employee, Cohort, Project, etc., and needs to match
    // the model name in the text field option enum on the backend).
    // This is used to query for available values
    relatedModel: string,

    // This should be the name of the field from the text field option
    // This is used to query for available values
    name: string,

    // This is used to disambiguate between multiple instances of a filter on the same
    // model & custom field.  For instance, we  may want to filter on an employee's custom field
    // and also on the timecard owner's same custom field, or member of a cohort and permitted
    // to a cohort.  The value should be something like "foreman_" or "member_" etc.
    disambiguation: string
): tFilterDef | tErrorFilterDef => {
    return referenceableSelectorFilterCreator({
        label,
        key: `companyTextFieldOptions-${relatedModel}-${name}${disambiguation || ""}` as tFilterKey,
        resourceName: "companyTextFieldOptions",
        multiselect: true,
        isSelectorV3: true,
        queryParam: `${disambiguation || ""}${relatedModel}_option_${name}`.toLowerCase(),
        // The primaryKey is technically not part of the type, because it's dynamically generated
        // but the type actually supports any string, not just the ones it's been told it does.
        primaryKey: `companyTextFieldOptions-${relatedModel}-${name}`,
        extraSearchFilters: { name: name, model: relatedModel },
    } as iFilterDefParams)
}

export const picklistItemCreator = (
    label: string,
    picklistName: string,
    queryParam: string
): tFilterDef | tErrorFilterDef => {
    return referenceableSelectorFilterCreator({
        label,
        key: `picklistItems-${picklistName}` as tFilterKey,
        resourceName: "picklistItems",
        multiselect: true,
        isSelectorV3: true,
        queryParam,
        // The primaryKey is technically not part of the type, because it's dynamically generated
        // but the type actually supports any string, not just the ones it's been told it does.
        primaryKey: `picklistItems-${picklistName}`,
        extraSearchFilters: { picklist_name: picklistName },
    } as iFilterDefParams)
}
