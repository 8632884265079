import React, { Component } from "react"
import { getCellValue } from "../../../common/ag-grid-utils"

class CostCodeDetailCellRenderer extends Component {
    refresh() {
        return false
    }

    render() {
        const value = getCellValue(this.props)
        // value should never be undefined, but occasionally it is (probably while the page
        // is still loading), so catch that here
        if (!value) {
            return <div className="group-cell"></div>
        }

        const title = this.props.titleFormatter({ value })

        return (
            <div className="group-cell">
                <div>
                    <div className="group-cell-title">{title}</div>
                    <div className="group-cell-text">
                        {`${value.project.job_number} \u2022 ${value.project.name}`}
                    </div>
                </div>
            </div>
        )
    }
}

export default CostCodeDetailCellRenderer
